<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>账户管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 搜索与添加区 -->
    <el-row :gutter="24"> <!-- 行 -->
      <el-col :span="24"> <!-- 列 -->
        <el-input class="search-input" type="number" placeholder="请输入账户ID" v-model="queryInfo.id"  clearable></el-input>
        <el-input class="search-input" placeholder="请输入账户名" v-model="queryInfo.username" clearable></el-input>
        <el-select class="search-input" clearable v-model="queryInfo.role_id" placeholder="请选择">
          <el-option
            v-for="item in roleList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <el-button type="primary" @click="searchDataClick" plain>搜索</el-button>
        <el-button type="primary" @click="addUserClick" plain>新增</el-button>
      </el-col>
    </el-row>
    <table-ui
      :propList="propList"
      :operationProps="operationProps"
      :listData="userList"
      v-bind="otherProps"
      :listCount="userTotal"
      v-model="queryInfo"
      @page="pageChange"
    >
      <template #isAdminSlot="scope">
        <el-tag v-if="scope.row.is_admin">是</el-tag>
        <el-tag v-else  type="info">否</el-tag>
      </template>
      <template #operation="scope">
        <span @click="editClick(scope.row)" style="margin-right: 10px;cursor: pointer;">编辑</span>
        <el-popconfirm title="确定删除？" @confirm="deleteClick(scope.row.id)">
          <span slot="reference" style="color: red;cursor: pointer;">删除</span>
        </el-popconfirm>
      </template>
    </table-ui>
    <add-user-drawer :show="addUserDrawerShow" @close="addUserDrawerClose"></add-user-drawer>
    <edit-user-drawer :show="editUserDrawerShow" :params="editUserInfo" @close="editUserDrawerClose"></edit-user-drawer>
  </div>
</template>
<script>
import TableUi from '@/components/table-ui/table-ui.vue'
import AddUserDrawer from './cnps/add-user-drawer.vue'
import EditUserDrawer from './cnps/edit-user-drawer.vue'
import { propList, operationProps, otherProps } from './config/adminUserConfig.js'
import { mapState, mapActions } from 'vuex'
import { deleteUser } from '@/api/adminUser/adminUser'
export default {
  components: {
    TableUi,
    AddUserDrawer,
    EditUserDrawer
  },
  data() {
    return {
      propList,
      operationProps,
      otherProps,
      queryInfo: {
        cur: 1,
        size: 10,
        id: '',
        username: '',
        role_id: '',
      },
      addUserDrawerShow: false,
      editUserDrawerShow: false,
      editUserInfo: {},
    }
  },
  created() {
    this.getUserRoleList()
    this.getList()
  },
  methods: {
    getList() {
      this.getUserList(this.queryInfo)
    },
    searchDataClick() {
      this.queryInfo.cur = 1
      this.getList()
    },
    ...mapActions('adminUser', ['getUserList', 'getUserRoleList']),
    pageChange(ev) {
      this.queryInfo.cur = ev.cur
      this.queryInfo.size = ev.size
      this.getList()
    },
    addUserClick() {
      this.addUserDrawerShow = true;
    },
    addUserDrawerClose () {
      this.searchDataClick()
      this.addUserDrawerShow = false;
    },
    async deleteClick (id) {
      const result = await deleteUser(id)
      if (result.success) {
        this.$message.success('已删除')
        if (result.data.exit) {
          location.href = '/'
        }
        this.getList()
      }
    },
    editClick(row) {
      this.editUserInfo = row
      this.editUserDrawerShow = true
    },
    editUserDrawerClose () {
      this.searchDataClick()
      this.editUserDrawerShow = false;
    },
  },
  computed: {
    ...mapState('adminUser', ['userList', 'userTotal', 'roleList']),
  },
}
</script>
<style scoped>
.search-input {
  width: 160px;
  margin-right: 10px;
}
.samllImg {
  width: 50px;
  height: 50px;
}
</style>

