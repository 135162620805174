<template>
  <div>
    <el-drawer :visible.sync="show"  :before-close="close" size="30%" :modal="false" :with-header="false" @open="openDrawer">
      <el-form ref="ruleFormRef" :model="ruleForm" label-width="80px">
        <el-form-item label="账户名">
          <span>{{ ruleForm.username }}</span>
        </el-form-item>
        <el-form-item label="账户密码" prop="password">
          <el-input v-model="ruleForm.password" type="password"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="role_id">
          <el-select clearable v-model="ruleForm.role_id" placeholder="请选择角色">
            <el-option
              v-for="item in roleList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="管理员" prop="is_admin">
          <el-switch
            v-model="ruleForm.is_admin"
            active-color="#13ce66"
            inactive-color="#999999">
          </el-switch>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">确定</el-button>
          <el-button @click="close">取消</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { editUser } from '@/api/adminUser/adminUser'
import md5 from 'js-md5';
export default {
  components: {
    
  },
  data() {
    return {
      ruleForm: {
        id: '',
        username: '',
        password: '',
        is_admin: false,
        role_id: '',
      }
    }
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    params: {
      required: true,
      type: Object,
    }
  },
  methods: {
    close() {
      this.$refs.ruleFormRef.resetFields()
      this.$emit('close')
    },
    openDrawer () {
      this.ruleForm = this.params
    },
    async submit() {
      const result = await editUser({
        id: this.ruleForm.id,
        password: this.ruleForm.password ? md5(this.ruleForm.password) : '',
        is_admin: this.ruleForm.is_admin,
        role_id: this.ruleForm.role_id,
      })
      if (result.success) {
        this.$message.success('修改成功')
        this.close()
      }
    },
  },
  computed: {
    ...mapState('adminUser', ['roleList']),
  },
}
</script>
<style scoped lang="less">

</style>
