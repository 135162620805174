<template>
  <div>
    <el-drawer :visible.sync="show"  :before-close="close" size="30%" :modal="false" :with-header="false">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="80px">
        <el-form-item label="账户名" prop="username">
          <el-input v-model="ruleForm.username"></el-input>
        </el-form-item>
        <el-form-item label="账户密码" prop="password">
          <el-input v-model="ruleForm.password" type="password"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="role_id">
          <el-select clearable v-model="ruleForm.role_id" placeholder="请选择角色">
            <el-option
              v-for="item in roleList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="管理员" prop="is_admin">
          <el-switch
            v-model="ruleForm.is_admin"
            active-color="#13ce66"
            inactive-color="#999999">
          </el-switch>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">立即创建</el-button>
          <el-button @click="close">取消</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { addUser } from '@/api/adminUser/adminUser'
import md5 from 'js-md5';
export default {
  components: {
    
  },
  data() {
    return {
      ruleForm: {
        username: '',
        password: '',
        is_admin: false,
        role_id: '',
      },
      rules: {
        username: [
          { required: true, message: '请输入账户名', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
        ],
        role_id: [
        { required: true, message: '请选择角色', trigger: 'blur' },
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    }
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    }
  },
  methods: {
    close() {
      this.$refs.ruleFormRef.resetFields()
      this.$emit('close')
    },
    async submit() {
      this.$refs.ruleFormRef.validate(async (v) => {
        if (!v) return
        const result = await addUser({
          username: this.ruleForm.username,
          password: md5(this.ruleForm.password),
          is_admin: this.ruleForm.is_admin,
          role_id: this.ruleForm.role_id,
        })
        if (result.success) {
          this.$message.success('添加成功')
          this.close()
        }
      })
    },
  },
  computed: {
    ...mapState('adminUser', ['roleList']),
  },
}
</script>
<style scoped lang="less">

</style>
