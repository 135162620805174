export let propList = [
  {
    prop: 'id',
    label: 'ID',
    align: 'center',
    width: '80',
    webId: 1,
    fixed: 'left',
  },
  {
    prop: 'username',
    label: '账户名',
    minWidth: '150',
    align: 'center',
    showOverflowTooltip: true,
    copy: true,
    webId: 2
  },
  {
    prop: 'is_admin',
    label: '管理员',
    minWidth: '300',
    align: 'center',
    webId: 3,
    slotName: 'isAdminSlot',
    showOverflowTooltip: true
  },
  {
    prop: 'role_name',
    label: '角色',
    minWidth: '200',
    align: 'center',
    showOverflowTooltip: true,
    webId: 4
  },
  {
    prop: 'create_time',
    label: '创建时间',
    minWidth: '200',
    align: 'center',
    dateFormat: 'dateTime',
    showOverflowTooltip: true,
    webId: 5
  }
]
export let operationProps = {
  isShow: true, //设置后面的选择选择条例出不出现
  checkedCities: [1, 2, 3, 4, 5], //配置默认出现的条例对应上方的webId
  checkeList: [
    //配置有哪些条咧id对应上方的webId
    { id: 1, label: 'ID' },
    { id: 2, label: '账户名' },
    { id: 3, label: '管理员' },
    { id: 4, label: '角色' },
    { id: 5, label: '创建时间' }
  ],
  width: 150, //配置选择栏的样式 宽带是多少
  align: 'center' //字体居中
}
export let otherProps = {
  showSelectColumn: false, //是否显示多选框
  showFooter: true //显示底部分页
}
